.splash-start-btn {
  margin: 12px 4px;
  width: 100px;
}

.gametype-btn {
  margin: 12px 4px;
  width: 200px;
}

.setupscreen {
  margin: 24px;
}

.ai-setup {
  width: 100%;
}
.ai-setup-start-btn {
  margin: 12px 4px;
  width: 200px;
}
.ai-setup-label {
  margin-top: 20px;
  font-size: 150%;
}
.ai-setup-difficulty-div {
  width: 85%;
  margin: 0 auto;
}
.ai-setup-difficulty-container {
  display: flex;
  justify-content: space-between;
}
.ai-setup-difficulty-img {
  align-self: flex-end;
}
.ai-setup-difficulty-range {
  margin-top: 15px;
}

.ai-setup-radio {
  margin-left: 24px;
  margin-right: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.ai-setup-radio-label {
  margin: 4px 10px;
}
.ai-player-desc {
  margin: 12px 8px;
}

.undobar-div {
  width: 100%;
}
.undo-btn {
  margin: 2px 4px;
  width: 48px;
}

.exit-btn {
  margin: 2px 4px;
  width: 48px;
}

.credits-birthday {
  margin: 16px;
  width: 90%;
  max-width: 460px;
  text-align: center;
}

.credits {
  margin: 16px;
  width: 90%;
  max-width: 460px;
  text-align: center;
}

.rules {
  margin: 16px;
  width: 90%;
  max-width: 460px;
  text-align: left;
}

.exampleGoban {
  margin: 20px;
}

.player-div {
  width: 100%;
}
.player-btn {
  margin: 4px 4px;
  width: 48%;
}
.player-1-btn {
  left: 0px;
}
.player-2-btn {
  right: 0px;
}
.waiting-player-btn {
  border: 2px solid #000000;
}
.active-player-btn {
  border: 2px solid #ffffff;
}
.winning-player-btn {
  border: 2px solid #000000;
}
.losing-player-btn {
  border: 2px solid #000000;
}
